import React, { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation';
import Image from "next/image";

import { RiBarChartHorizontalLine, RiCloseLine, RiCopperDiamondLine, RiFilePdfFill, RiFlagLine, RiGalleryFill, RiHomeLine, RiInstagramLine, RiLandscapeLine, RiMailLine, RiMessage2Line, RiMoneyDollarCircleLine, RiNotification3Line, RiOrganizationChart, RiPercentLine, RiPhoneLine, RiQuestionLine, RiRotateLockLine, RiSearch2Line, RiShieldCheckLine, RiShieldKeyholeLine, RiShieldLine, RiShutDownLine, RiTelegramLine, RiTiktokLine, RiTwitterLine, RiUserAddLine, RiWhatsappLine } from 'react-icons/ri';

import { Popover, Transition, Menu } from "@headlessui/react";
import useDarkMode from "./useDarkMode";
import { useTranslation } from 'next-i18next';

const solutions = [
    {
        name: "Beranda",
        description: "Farm Homepage.",
        href: "/",
        icon: RiHomeLine,
    },
];
const resources = [
    {
        name: "Whatsapp 1",
        description:
            "Get all of your questions answered in our forums or contact support.",
        href: "https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+Booking+mobil+di%20sultanhorse.+Terima+kasih&type=phone_number&app_absent=0",
        icon: RiWhatsappLine,
    },
    {
        name: "Instagram",
        description:
            "Get all of your questions answered in our forums or contact support.",
        href: "https://instagram.com/sultanhorse",
        icon: RiInstagramLine,
    },
    {
        name: "Tiktok",
        description:
            "Get all of your questions answered in our forums or contact support.",
        href: "https://www.tiktok.com/@sultan.horse",
        icon: RiTiktokLine,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function Header() {
    const { t } = useTranslation();

    const [colorTheme, setTheme] = useDarkMode();
    const [scrolled, setScrolled] = useState(false);


    const router = useRouter();
    let pathname = router.pathname;
    useEffect(() => {
        pathname = router.pathname;
    }, [router.pathname]);

    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const activ = usePathname();

    return (
        <>
            <Popover className="fixed z-20 w-full bg-white/80 backdrop-blur dark:bg-woodsmoke-950">
                <div className={scrolled ? 'hidden' : 'block px-6 py-2 text-white bg-woodsmoke-500 h-24'}>
                    <marquee>
                        <div className="flex items-center gap-3">
                            <span className="flex w-3 h-3">
                                <span className="absolute inline-flex w-3 h-3 bg-white rounded-full opacity-75 animate-ping"></span>
                                <span className="relative inline-flex w-3 h-3 bg-white rounded-full"></span>
                            </span>
                            <div className="mt-1">{t('description')}</div>
                        </div>
                    </marquee>
                </div>
                <div className="px-4 py-4 mx-auto -mt-12 bg-white rounded-t-2xl lg:px-28 md:px-12 sm:px-8 md:py-0 lg:py-0 xl:py-0 dark:bg-woodsmoke-800">
                    <div className="flex items-center justify-between md:justify-between md:space-x-4">
                        <div className="flex items-center justify-start gap-2">
                            <Link
                                href="/"
                                className={
                                    activ === '/'
                                        ? "text-woodsmoke-50 font-medium mr-6 flex items-center gap-1"
                                        : "text-gray-900 dark:text-white mr-6 font-medium flex items-center gap-1"
                                }
                                aria-label="logo this"
                            >
                                {colorTheme === "dark" ? (
                                    <Image src="/images/logo-dark.webp" width="120" height="39" alt="logo-dark" />) : (
                                    <Image src="/images/logo-white.webp" width="120" height="39" alt="logo-white" />
                                )}
                            </Link>
                        </div>
                        <div className="flex items-center self-end justify-end w-full gap-2 -mr-2 flex-end lg:hidden">
                            <Menu as="div" className="relative inline-block w-full py-2 text-left md:p-2">
                            </Menu>

                            <Popover.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md dark:bg-gray-800 hover:black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <RiBarChartHorizontalLine className="w-6 h-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden space-x-10 lg:flex">
                            <Link
                                href="/"
                                className={activ === '/'
                                    ?
                                    "text-woodsmoke-50 font-medium bg-tear bg-no-repeat py-5 active"
                                    : "dark:text-white text-gray-900 font-medium py-5"
                                }
                            >
                                {t('home')}
                            </Link>
                            <Link
                                href="/rental"
                                className={activ === '/'
                                    ?
                                    "text-woodsmoke-50 font-medium bg-tear bg-no-repeat py-5 active flex items-center gap-1"
                                    : "dark:text-white text-gray-900 font-medium py-5 flex items-center gap-1"
                                }
                            >
                                {t('rent')}
                            </Link>
                            <Link
                                href="/"
                                className={activ === '/'
                                    ?
                                    "text-woodsmoke-50 font-medium bg-tear bg-no-repeat py-5 active flex items-center gap-1"
                                    : "dark:text-white text-gray-900 font-medium py-5 flex items-center gap-1"
                                }
                            >
                                {t('shuttle')} <div className="text-red-500">({t('soon')})</div>
                            </Link>
                            <Link
                                href="/"
                                className={activ === '/'
                                    ?
                                    "text-woodsmoke-50 font-medium bg-tear bg-no-repeat py-5 active flex items-center gap-1"
                                    : "dark:text-white text-gray-900 font-medium py-5 flex items-center gap-1"
                                }
                            >
                                {t('topupbill')} <div className="text-red-500">({t('soon')})</div>
                            </Link>
                            {/* <Link
                                href="/"
                                className={activ === '/artikel'
                                    ?
                                    "text-woodsmoke-500 font-medium bg-tear bg-no-repeat py-5 active"
                                    : "dark:text-white text-gray-900 font-medium py-5"
                                }
                            >
                                Artikel
                            </Link> */}
                        </Popover.Group>
                        <div className="items-center justify-end hidden gap-2 md:hidden lg:flex md:flex-1 lg:w-0">
                            <div className="hidden gap-2 py-2 md:p-2">
                                <a href="/login" className="flex items-center gap-2 px-4 py-2.5 text-white rounded-lg bg-woodsmoke-500">
                                    Masuk
                                </a>
                                <a href="/register" className="flex items-center gap-2 px-4 py-2.5 text-white rounded-lg bg-transparent border border-woodsmoke-500">
                                    Daftar
                                </a>
                            </div>

                            <div className="">
                                {colorTheme === "light" ? (
                                    <div className="px-2.5 py-2.5 text-black bg-white rounded-lg dark:bg-gray-800 dark:text-white">
                                        <svg
                                            onClick={() => setTheme("light")}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                                            />
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="px-2.5 py-2.5 text-white bg-gray-900 rounded-lg">
                                        <svg
                                            onClick={() => setTheme("dark")}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="relative inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden"
                    >
                        <div className="z-20 bg-white divide-y-2 rounded-lg shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-gray-50 dark:divide-gray-900">
                            <div className="px-5 pt-5 pb-6">
                                <div className="flex items-center justify-between">
                                    <div className="-mr-2">
                                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md dark:bg-gray-900 hover:black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>

                                <div>
                                    <div className="py-2 md:p-2">
                                    </div>

                                    <div className="flex items-center justify-between gap-2">
                                        {colorTheme === "light" ? (
                                            <div className="px-2 py-2 text-black rounded-lg">
                                                <img src="/images/logo-dark.webp" width="100px" alt="" />
                                            </div>
                                        ) : (
                                            <div className="px-2 py-2 text-white rounded-lg">
                                                <img src="/images/logo-dark.webp" width="100px" alt="" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid gap-y-8">
                                        {solutions.map((item, solutionsIndex) => (
                                            <Link
                                                key={solutionsIndex}
                                                href={item.href}
                                                prefetch={false}
                                            >
                                                <div className="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                                                    <item.icon
                                                        className="flex-shrink-0 w-6 h-6 text-woodsmoke-500"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-400">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </Link>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                            <div className="px-5 py-6 space-y-6">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                    {resources.map((item, resourcesIndex) => (
                                        <a
                                            key={resourcesIndex}
                                            href={item.href}
                                            className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-gray-400"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
            {/* <ModalWeb3 /> */}
        </>
    );
};

export const Footer = () => {
    const { t } = useTranslation();
    const [colorTheme, setTheme] = useDarkMode();
    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <footer className="px-4 pt-20 pb-12 transition-all duration-500 bg-gray-100 dark:bg-woodsmoke-800 lg:px-28 md:px-16 linear">
            <section className="pb-2 mx-auto">
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-0 gap-y-6">
                    <div className="">
                        <div className="flex items-center justify-start gap-1 mb-5">
                            {colorTheme === "dark" ? (
                                <Image src="/images/logo-dark.webp" width="140" height="39" alt="logo-dark" />) : (
                                <Image src="/images/logo-white.webp" width="140" height="39" alt="logo-white" />
                            )}
                        </div>
                        <div className="space-y-4">
                            <span className="text-white">
                                {t('tagline')}
                            </span>
                        </div>
                    </div>
                    <div className="pl-1 md:pl-0 lg:pl-10">
                        <h2 className="mb-5 text-2xl font-semibold text-black title-font dark:text-yellow-500">
                            {t('service')}
                        </h2>
                        <ul className="space-y-2 text-black dark:text-gray-200">
                            <li>
                                <a className="hover:text-woodsmoke-500">
                                    {t('rentvehicle')}
                                </a>
                            </li>
                            <li>
                                <a className="hover:text-woodsmoke-500">
                                    {t('shuttle')}
                                </a>
                            </li>
                            <li>
                                <a className="hover:text-woodsmoke-500">
                                    {t('sendpackage')}
                                </a>
                            </li>
                            <li>
                                <a className="hover:text-woodsmoke-500">
                                    {t('topupbill')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="pl-1 md:pl-0 lg:pl-10">
                        <h2 className="mb-5 text-2xl font-semibold text-black title-font dark:text-yellow-500">
                            {t('openinghours')}
                        </h2>
                        <ul className="space-y-2 text-black dark:text-gray-200">
                            <li>24 {t('hour')}  <br />({t('prioritytext')})</li>
                        </ul>
                    </div>
                    <div className="pl-1 md:pl-12 lg:pl-10">
                        <h2 className="mb-5 text-2xl font-semibold text-black title-font dark:text-yellow-500">
                            {t('forsupport')}
                        </h2>
                        <ul className="space-y-5 text-black dark:text-gray-200">
                            <li>
                                <a href="mailto:support@sultanhorse.com" className="flex">
                                    <RiMailLine className="w-6 h-6 mr-2" /> {t('forsupport')} <br />
                                    support@sultanhorse.com
                                </a>
                            </li>
                            <li>
                                <a href="mailto:sultanhorse@gmail.com" className="flex">
                                    <RiMailLine className="w-6 h-6 mr-2" /> {t('forsupport')} <br />
                                    sultanhorse@gmail.com
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mx-auto border-color">
                    <div className="mt-8">
                        <hr className="border-gray-500 dark:border-gray-900" />
                    </div>
                    <div className="container flex flex-col items-center pt-12 mx-auto space-y-5 lg:flex-row lg:space-y-0">
                        <nav className="flex flex-col items-center justify-center text-black dark:text-white lg:flex-row">
                            <p>Copyright © {getYear()} Sultan Horse. All rights reserved.</p>
                        </nav>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export const Toast = ({
    title = "",
    children,
    isOpen = false,
    onClose = (e) => { },
    duration = 60000,
}) => {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        setShowToast(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!showToast) return;
        setTimeout(() => {
            setShowToast(false);
            onClose(true);
        }, duration);
    }, [showToast]);

    return (
        <>
            {showToast ? (
                <>
                    <div className="fixed inset-0 z-50 flex justify-center pt-5 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-full px-4 mx-auto md:w-2/4 lg:w-2/4">
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-3 bg-green-600 border-b border-green-600 border-solid rounded-t">
                                    <h3 className="flex items-center text-sm font-bold text-white rounded-lg">
                                        <RiNotification3Line className="w-4 h-4 mr-1" /> {title}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-sm font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-2 focus:outline-none"
                                        onClick={() => {
                                            setShowToast(false);
                                            onClose(false);
                                        }}
                                    >
                                        <RiCloseLine className="text-white" />
                                    </button>
                                </div>

                                <div className="relative flex-auto p-3 text-xs">{children}</div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};
